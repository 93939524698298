.timeline-tag {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.timeline-tag.education {
  background-color: #3498db; /* 蓝色 */
}

.timeline-tag.tech {
  background-color: #2ecc71; /* 绿色 */
}

.timeline-tag.finance {
  background-color: #f39c12; /* 橙色 */
}

.timeline-tag.consulting {
  background-color: #9b59b6; /* 紫色 */
}

.timeline-tag.other {
  background-color: #95a5a6; /* 灰色 */
}
