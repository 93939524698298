.btn.btn-primary.btn-lg.btn-load-more {
  background-color: #4ac1bc !important;
  border-color: #4ac1bc !important;
  color: #fff !important;
}

.btn.btn-primary.btn-lg.btn-load-more:hover {
  background-color: #5de2dd !important;
  border-color: #5de2dd !important;
}
