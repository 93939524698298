.ant-dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 3;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.ant-dropdown-selector {
  width: 200px;
  height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  outline: none;
  appearance: none;
}

.ant-dropdown:hover {
  border-color: #cddbe7;
}

.ant-dropdown-selector:focus {
  border-color: #e9edf0;
  outline: none;
}

.ant-dropdown-selector::-ms-expand {
  display: none;
}

.ant-dropdown::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  border-top: 4px solid #999;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transition: all 0.3s;
}

.left-arrow i {
  font-size: 22px; /* 调整字体大小 */
  transition: all 0.3s ease; /* 添加过渡效果 */
  color: #ffffff;
}

.left-arrow-text {
  font-size: 24px; /* 调整字体大小 */
  transition: all 0.3s ease; /* 添加过渡效果 */
  color: #ffffff;
}

.left-arrow:hover i {
  color: #c4e5f1; /* 鼠标悬停时的颜色 */
  transform: scale(1.2); /* 鼠标悬停时的放大效果 */
}

.left-arrow:hover .left-arrow-text {
  color: #c4e5f1; /* 鼠标悬停时的颜色 */
  transform: scale(1.1); /* 鼠标悬停时的放大效果 */
}

@keyframes dynamic-border {
  0%,
  20% {
    border: 5px solid rgb(231, 245, 250);
  }
  40% {
    border: 5px solid rgb(248, 248, 248);
  }
  60%,
  100% {
    border: 5px solid lightblue;
  }
}

.project:hover {
  animation: dynamic-border 5s infinite;
}

.project:hover h3 {
  color: #f5fafc !important; /* 鼠标悬停时的颜色 */
  transform: scale(1.1); /* 鼠标悬停时的放大效果 */
}

.row {
  margin-top: 25px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Add this line */
}
