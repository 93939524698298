.btn-github-snake {
  display: flex;
  justify-content: center;
  background-color: black;
  color: white; /* 文字颜色为白色，以确保可读性 */
  border-color: black; /* 边框颜色也设为黑色 */
}

.btn-github-snake:hover {
  background-color: #333; /* 按钮悬停时的背景色 */
  border-color: #333; /* 按钮悬停时的边框颜色 */
}

/* 在小屏幕设备上启用滚动效果 */
@media (max-width: 768px) {
  .about-desc {
    overflow-x: scroll; /* 水平滚动 */
    -webkit-overflow-scrolling: touch; /* 支持iOS上的平滑滚动 */
    white-space: nowrap; /* 不换行 */
  }

  .about-desc img {
    max-width: 100%; /* 图片宽度最大100% */
    height: auto; /* 图片高度自动调整 */
  }
}
