@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fade-in-up 1s ease-out both;
}

body {
  font-family: Arial, sans-serif;
}

.fade-in-up:nth-child(1) {
  animation-delay: 0.2s;
}

.fade-in-up:nth-child(2) {
  animation-delay: 0.4s;
}

.fade-in-up:nth-child(3) {
  animation-delay: 0.6s;
}

.fade-in-up:nth-child(4) {
  animation-delay: 0.8s;
}

.fade-in-up:nth-child(5) {
  animation-delay: 1s;
}

.fade-in-up:nth-child(6) {
  animation-delay: 1.2s;
}

.fade-in-up:nth-child(7) {
  animation-delay: 1.4s;
}

.fade-in-up:nth-child(8) {
  animation-delay: 1.6s;
}

.project-detail-header {
  width: 100%;
  height: 200px; /* adjust as needed */
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-detail-header-image {
  position: relative;
  width: 100%;
  padding-top: 20vh; /* adjust this value based on the aspect ratio of your image */
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.project-detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

/* Make all cards full width */
.project-detail-content .project-detail-description,
.project-detail-content .project-detail-duration,
.project-detail-content .project-detail-my-role,
.project-detail-content .project-detail-tech-stack,
.project-detail-content .project-detail-readme,
.project-detail-content .project-detail-in-progress {
  width: 90%;
}

/* Card styling */
.project-detail-content .project-detail-description .ant-card,
.project-detail-content .project-detail-duration .ant-card,
.project-detail-content .project-detail-my-role .ant-card,
.project-detail-content .project-detail-tech-stack .ant-card,
.project-detail-content .project-detail-readme .ant-card,
.project-detail-content .project-detail-in-progress .ant-card {
  box-shadow: 0 2px 4px 0 rgba(5, 5, 5, 0.144);
  transition: 0.3s;
}

.project-detail-in-progress-image {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  pointer-events: none;
}

/* Hover state */
.project-detail-content .project-detail-description .ant-card:hover,
.project-detail-content .project-detail-duration .ant-card:hover,
.project-detail-content .project-detail-my-role .ant-card:hover,
.project-detail-content .project-detail-tech-stack .ant-card:hover,
.project-detail-content .project-detail-readme .ant-card:hover {
  box-shadow: 0 4px 8px 0 rgba(90, 141, 158, 0.055);
}

.project-detail-content .ant-card .ant-card-body {
  flex-direction: column;
  align-items: start;
}

.project-detail-content .ant-list {
  width: 90%;
}

.project-detail-content .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.project-detail-carousel {
  width: 95%;
  margin: 0 auto;
  height: 400px; /* Adjust based on your needs */
  overflow: hidden;
  height: "160px";
  color: #e3dede;
  line-height: "160px";
  text-align: "center";
  border-radius: 18px;
}

.project-detail-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
  transition: 0.3s;
}

.ant-btn-primary {
  background-color: #1da57a;
  border-color: #1da57a;
  color: #fff;
  margin: 20px 0;
  align-self: center;
}

.ant-btn-primary:hover {
  background-color: #178f61;
  border-color: #178f61;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-detail-container {
  opacity: 0;
  animation-name: appear;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.project-detail-content .ant-card {
  background-color: #fffffff0;
}

.project-detail-container.appear {
  animation-play-state: running;
}

.project-detail-left-arrow .project-detail-left-arrow-button {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.829);
  position: relative;
  top: 20px;
  left: 20px;
  font-size: 2em;
  border: none;
  z-index: 100;
  transition: color 0.3s ease;
  box-sizing: border-box; /* Include border and padding in element's total width and height */
}

.icon-shadow {
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
}

.custom-tag {
  color: #fff; /* 标签的字体颜色 */
  background-color: #09b9f3; /* 标签的背景颜色 */
  border: 1px solid #05a6db; /* 标签的边框颜色 */
  border-radius: 18px; /* 四角光滑 */
  padding: 5px 10px; /* 矩形的大小 */
  margin-right: 5px; /* 标签之间的间距 */
}

.project-detail-button-container .ant-btn {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
  height: auto;
  color: #fff;
  font-size: 2.2em;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  padding: 10px 0;
}

.project-detail-button-container .project-detail-demo-button {
  background-color: #1da57a;
  border-color: #1da57a;
}

.project-detail-button-container .project-detail-demo-button:hover {
  background-color: #178f61;
  border-color: #178f61;
}

.project-detail-button-container .project-detail-demo-video-button {
  background-color: #e74c3c;
  border-color: #e74c3c;
}

.project-detail-button-container .project-detail-demo-video-button:hover {
  background-color: #c0392b;
  border-color: #c0392b;
}

.project-detail-button-container .project-detail-download-button {
  background-color: #deab14;
  border-color: #deab14;
}

.project-detail-button-container .project-detail-download-button:hover {
  background-color: #c99e12;
  border-color: #c99e12;
}

.project-detail-button-container .project-detail-github-button {
  background-color: #000;
  border-color: #333;
}

.project-detail-button-container .project-detail-github-button:hover {
  background-color: #333;
  border-color: #333;
}
